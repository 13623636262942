// Generated by Framer (7699a62)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, Link, useActiveVariantCallback, useConstant, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["kC5LuHw2M", "F3CNGtTdT", "cImzvTui4", "TDIibdZuI"];

const serializationHash = "framer-M24Vj"

const variantClassNames = {cImzvTui4: "framer-v-rhic3", F3CNGtTdT: "framer-v-hik09w", kC5LuHw2M: "framer-v-4k6qyy", TDIibdZuI: "framer-v-wur0u8"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}, F3CNGtTdT: {damping: 50, delay: 0, mass: 0.1, stiffness: 600, type: "spring"}};

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {"Phone 2": "TDIibdZuI", "Variant 1": "kC5LuHw2M", "Variant 2": "F3CNGtTdT", Phone: "cImzvTui4"}

const getProps = ({height, id, image, width, ...props}) => { return {...props, C_XysNKAR: image ?? props.C_XysNKAR ?? {src: "https://framerusercontent.com/images/g0EwjnYr38cWOtaS9jxn3s1YR38.svg"}, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "kC5LuHw2M"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;image?: {src: string; srcSet?: string}; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, C_XysNKAR, ...restProps} = getProps(props)

const initialVariant = useConstant(() => variant)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "kC5LuHw2M", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onMouseEntersaxcbk = activeVariantCallback(async (...args) => {
setVariant("F3CNGtTdT")
})

const onMouseLeavedi9cir = activeVariantCallback(async (...args) => {
setVariant("kC5LuHw2M")
})

const onTap10nbnwg = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
setVariant("TDIibdZuI")
})

const onTaptnx4du = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
setVariant("cImzvTui4")
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Transition value={transition}><Link {...addPropertyOverrides({F3CNGtTdT: {href: "www.ppauto.sk"}, TDIibdZuI: {href: "www.ppauto.sk", openInNewTab: true}}, baseVariant, gestureVariant)}><Image {...restProps} animate={variants} background={{alt: "", fit: "fill", intrinsicHeight: 500, intrinsicWidth: 500, pixelHeight: 500, pixelWidth: 500, sizes: "min(427px, 100vw)", ...toResponsiveImage(C_XysNKAR)}} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-4k6qyy", className, classNames)} framer-12lh8ee`} data-framer-name={"Variant 1"} data-highlight initial={initialVariant} layoutDependency={layoutDependency} layoutId={"kC5LuHw2M"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onMouseEnter={onMouseEntersaxcbk} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{filter: "none", WebkitFilter: "none", ...style}} variants={{F3CNGtTdT: {filter: "blur(5px)", WebkitFilter: "blur(5px)"}, TDIibdZuI: {filter: "blur(5px)", WebkitFilter: "blur(5px)"}}} {...addPropertyOverrides({cImzvTui4: {"data-framer-name": "Phone", onMouseEnter: undefined, onTap: onTap10nbnwg}, F3CNGtTdT: {"data-framer-name": "Variant 2", as: "a", onMouseLeave: onMouseLeavedi9cir}, TDIibdZuI: {"data-framer-name": "Phone 2", as: "a", onMouseEnter: undefined, onTap: onTaptnx4du}}, baseVariant, gestureVariant)}/></Link></Transition>
</LayoutGroup>)

});

const css = [".framer-M24Vj[data-border=\"true\"]::after, .framer-M24Vj [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-M24Vj.framer-12lh8ee, .framer-M24Vj .framer-12lh8ee { display: block; }", ".framer-M24Vj.framer-4k6qyy { height: 177px; overflow: hidden; position: relative; width: 427px; }", ".framer-M24Vj.framer-v-hik09w.framer-4k6qyy { text-decoration: none; }", ".framer-M24Vj.framer-v-rhic3.framer-4k6qyy { cursor: pointer; }", ".framer-M24Vj.framer-v-wur0u8.framer-4k6qyy { cursor: pointer; text-decoration: none; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 177
 * @framerIntrinsicWidth 427
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"F3CNGtTdT":{"layout":["fixed","fixed"]},"cImzvTui4":{"layout":["fixed","fixed"]},"TDIibdZuI":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"C_XysNKAR":"image"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 */
const FramerRfjUK9k38: React.ComponentType<Props> = withCSS(Component, css, "framer-M24Vj") as typeof Component;
export default FramerRfjUK9k38;

FramerRfjUK9k38.displayName = "PP auto logo c";

FramerRfjUK9k38.defaultProps = {height: 177, width: 427};

addPropertyControls(FramerRfjUK9k38, {variant: {options: ["kC5LuHw2M", "F3CNGtTdT", "cImzvTui4", "TDIibdZuI"], optionTitles: ["Variant 1", "Variant 2", "Phone", "Phone 2"], title: "Variant", type: ControlType.Enum}, C_XysNKAR: {__defaultAssetReference: "data:framer/asset-reference,g0EwjnYr38cWOtaS9jxn3s1YR38.svg?originalFilename=pp+auto+logo.svg&preferredSize=auto", title: "Image", type: ControlType.ResponsiveImage}} as any)

addFonts(FramerRfjUK9k38, [])